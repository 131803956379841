import styled from "styled-components";

export const LastCardKnackLeft = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 1rem;

  svg {
    margin: 0;
  }
  @media (max-width: 1024px) {
    margin-top: 1rem !important;
  }
  @media (max-width: 820px) {
    font-size: 10px;
    gap: 16px;
  }

  @media (max-width: 769px) {
    justify-content: center;
  }
  @media (max-width: 600px) {
    justify-content: flex-start;
    margin-top: 0.5rem !important;
  }
  @media (max-width: 375px) {
    .client-name {
      display: none;
    }
  }
`;

export const ServiceInner = styled.div`
  font-size: 56px;
  font-weight: 600;
  line-height: 65px;
  text-align: center;
  text-transform: capitalize;

  @media (max-width: 1024px) {
    font-size: 35px;
    font-weight: 500;
    line-height: 45px;
    padding-left: 0rem;
  }
  &.WVedDesignedTitle {
    font-size: 46px;
    line-height: 56px;
  }
  @media (max-width: 820px) {
    &.WVedDesignedTitle {
      font-size: 34px;
      line-height: 46px;
      /* br {
        display: none;
      } */
    }
  }
  @media (max-width: 600px) {
    letter-spacing: -0.5px;
    font-size: 26px;
    line-height: 36px;
  }

  @media (max-width: 375px) {
    &.WVedDesignedTitle {
      font-size: 21px;
      line-height: 30px;
    }
  }
  @media (max-width: 430px) {
    &.WVedDesignedTitle {
      font-size: 28px;
      line-height: 36px;
      br {
        display: none;
      }
    }
    &.RealDesignTitle {
      font-size: 26px;
      line-height: 30px;
    }
  }
`;

export const ServicesBottomIcon = styled.div`
  position: absolute;
  top: -446px;
  right: -223px;
  @media (max-width: 1180px) {
    right: -141px;
  }
  @media (max-width: 1024px) {
    margin-top: 5.5rem;
    right: -165px;
    svg {
      width: 245px;
    }
    img {
      width: 245px;
    }
  }
  @media (max-width: 914px) {
    right: 0px;
    top: -430px;
    display: none;
    svg,
    img {
      width: 50%;
    }
  }
  @media (max-width: 768px) {
    svg,
    img {
      display: none;
    }
  }
`;

export const ServicesInnerCard = styled.div`
  width: 540px;
  min-height: auto;
  ${"" /* max-height: 304px; */}
  position: absolute;
  bottom: -180px;
  right: -300px;
  border: 1px solid #f6f4f2;
  border-radius: 14px;
  padding: 2rem;
  background: #ffffff;
  padding-right: 5rem;
  @media (min-width: 1400px) {
    width: 540px;
    right: -365px;
    padding-right: 3rem;
  }
  @media (max-width: 1200px) {
    width: 540px;
    right: -350px;
    padding: 2rem;
    padding: 2rem;
  }
  @media (max-width: 1024px) {
    right: -335px;
    width: 480px;
  }

  @media (max-width: 820px) {
    padding: 2rem;
    width: 420px;
    right: -346px;
    min-height: 200px;
    bottom: -90px;
    &.WeVedDesignedCardBottom {
      position: unset;
      width: 100%;
      margin-top: 4rem;
      padding: 1rem;
    }
  }
  @media (max-width: 540px) {
    position: relative;
    width: 100%;
    padding: 1rem;
    left: 0;
    /* margin-bottom: 0.5rem; */
    bottom: -20px;
  }
  @media (max-width: 475px) {
    position: relative;
    width: 100%;
    padding: 1rem;
    left: 0;
    /* margin-bottom: 0.5rem; */
    bottom: -20px;
    &.home-industries {
      position: absolute;
      width: 100%;
      padding: 1rem;
      left: 0;
      bottom: -220px;
    }
    &.oddoCard {
      top: 18px;
    }
  }
  @media only screen and (max-width: 950px) and (max-height: 500px) {
    svg {
      display: none;
    }
  }
`;

export const ServicesInnerText = styled.div`
  font-size: 21px;
  font-weight: 400;
  line-height: 32px;
  font-family: Merriweather;

  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 820px) {
    font-size: 16px;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 26px;
  }
`;

export const ServicesItems = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.3px;
  margin-bottom: 0px;
  width: fit-content;
  .rightCross {
    rotate: 43deg;
    margin-left: 10px;
  }
  svg {
    right: 0;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
    position: relative;
  }
  &:hover {
    svg {
      right: -10px;
      transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
    }
  }
  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 26px;
    padding: 1rem 1.5rem;
  }

  @media (max-width: 820px) {
    font-size: 15px;
    line-height: 18px;
    padding: 1rem 1.5rem;
  }
  @media (max-width: 778px) {
    min-height: 40px;
    border-radius: 16px;
    padding: 1rem 1.5rem;
    font-size: 16px;
  }
  @media (max-width: 600px) {
    min-height: 35px;
  }
`;

export const ServicesItemsInner = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  color: #ffffff;

  svg {
    height: 26px;
    width: 26px;
  }
  @media (max-width: 1024px) {
    span {
      display: none;
    }
    font-size: 20px;
    line-height: 30px;
  }
  @media (max-width: 820px) {
    font-size: 16px;
    line-height: 15px;
  }
  @media (max-width: 600px) {
    span {
      display: none;
    }
    font-size: 14px;
    line-height: 15px;
  }
`;

export const ServicesLabel = styled.div`
  font-size: 17px;
  font-weight: 300;
  line-height: 25px;
  text-align: center;
  padding-top: 30px;
  max-width: 550px;
  margin: 0 auto;
  &.WVedDesignedText {
    font-size: 26px;
    line-height: 36px;
  }
  @media (min-width: 1440px) {
    padding-top: 50px;
  }
  @media (max-width: 1024px) {
    &.WVedDesignedText {
      font-size: 20px;
      line-height: 30px;
    }
  }
  @media (max-width: 820px) {
    &.WVedDesignedText {
      font-size: 16px;
      line-height: 26px;
    }
  }
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1px;
    margin-bottom: 16px;
  }
  @media (max-width: 479px) {
    padding-top: 20px;
  }
  @media (max-width: 430px) {
    &.WVedDesignedText {
      font-size: 14px;
      line-height: 22px;

      br {
        display: none;
      }
    }
  }
`;

export const ServicesLeft = styled.div`
  &.projectTeam {
    width: 60%;
  }
  &.HomeServicesLeft {
    margin-bottom: 60px;
  }
  @media (min-width: 1440px) {
  }
  @media (max-width: 768px) {
    &.projectTeam {
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    width: 100%;

    &.projectTeam {
      width: 100%;
    }
  }
  @media (max-width: 479px) {
    margin-bottom: 30px;
  }
`;

export const ServicesMain = styled.div`
  margin-bottom: 8rem;
  width: 100%;
  &.MerndAd {
    margin-bottom: 0rem;
  }
  &.demanddesign {
    padding-top: 0rem;
  }
  @media (max-width: 1024px) {
    /* padding-top: 6rem; */
    &.demanddesign {
      padding-top: 0rem;
    }
  }
  @media (max-width: 820px) {
    padding-top: 3rem;
    margin-top: 0;
  }
  @media (max-width: 778px) {
    padding-top: 3rem;
    margin-top: 0;
    margin-bottom: 0rem;
    width: 100%;

    &.codi-nater {
      padding-bottom: 4.5rem;
    }

    &.ELearningDesign {
      padding-top: 0rem;
      margin-top: 0rem;
    }
  }

  @media (max-width: 820px) {
    &.demanddesign {
      padding-top: 5rem;
    }
  }
  @media (max-width: 430px) {
    &.demanddesign {
      padding-top: 1rem;
    }
    &.RestaurantDesign {
      padding-top: 0rem;
    }
    &.RealDesign {
      padding-top: 0rem;
      padding-bottom: 0;
    }
  }

  &.WeVedDesigned {
    @media (max-width: 820px) {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0rem;
      .WeVedDesignedTitle {
        width: 100%;
      }
      .demanddesign {
        padding-top: 5rem;
      }
    }
  }
  &.WeVedDesigned {
    @media (max-width: 778px) {
      margin-top: 0;
    }
  }
  @media (max-width: 600px) {
    &.oddoHealth {
      margin-top: 0;
    }
    margin-top: 0;
  }
`;

export const ServicesMiddleCard = styled.div`
  margin: 12rem 0;
  margin-top: 0rem;
  background: #1d1e22;
  border: 1px solid #414141;
  border-radius: 16px;
  padding: 1.5rem 0;
  width: 542px;
  position: relative;

  @media (max-width: 1024px) {
    padding: 0.5rem 0;
    margin-bottom: 11rem;
    width: 40%;
    padding-bottom: 1rem;
    padding-top: 1rem !important;
  }
  @media (max-width: 914px) {
    width: 48%;
  }
  @media (max-width: 820px) {
    width: 45%;
    &.WeVedDesignedCard {
      padding-bottom: 16px;
      width: 45%;
      margin-bottom: 6rem;
    }
  }

  @media (max-width: 778px) {
    width: 100%;
    margin: 2rem 0;
  }
  @media (max-width: 768px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    padding-top: 5px;
    width: 100%;
    &.WeVedDesignedCard {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  @media (max-width: 475px) {
    width: 100%;
    &.WeVedDesignedCard {
      width: 100%;
      margin-bottom: 1rem;
    }
    &.home-main-industries {
      margin-bottom: 200px;
    }
  }
`;